<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('table.head.date') }}</span></div>
           <div class="fx">
             <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" :endDefault="endDefault" :configs="dateConfigs" />
            <button class="btn-search ml-5" type="button" @click="pageSeach">
              <i class="fas fa-search"></i>
            </button>
           </div>
         </div>
      </div>
      <memo></memo>
    </div>
    <div class="main-contents-wrapper">
      <article class="total-info-container">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <img :src="require('@/assets/img/searchicon.png')" />
            <i class="fas fa-search"></i>
            <span>{{ $t('searchArea.schResult') }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value">{{ pageInfo.tatal_list_count }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value">{{ pageInfo.page }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span class="value">{{ pageInfo.tatal_page_count }}</span>
          </div>
          <button type="button" class="btn-layout btn-grey" @click="openDetail('c')">{{ $t('button.add') }}</button>
        </div>
      </article>
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody>
          <template v-if="tableData.length > 0">
            <tr v-for="(item, idx) in tableData" :key="item">
              <td class="roboto">{{ Number(item.siteCheckIdx)}}</td>
              <td>
                <button type="button" class="fc-nick btn-link" @click="openDetail('u', item.siteCheckIdx)">{{ item.checkTypeName }}</button>
              </td>
              <td class="roboto">{{ item.startDate}}</td>
              <td class="roboto">{{ item.endDate}}</td>
              <!--td>{{ item.isEmergency === 'Y' ? '예': '아니오'}}</td-->
              <td>{{ item.siteCheckStatus }}</td>
              <td>
                <button type="button" class="fc-id btn-link" v-if="item.siteCheckStatus !== 'close'" @click="forceStop(item)">{{ $t('table.head.maintaincompl') }}</button>
              </td>
            </tr>
          </template>
          <template v-else>
            <td colspan="8">{{ $t('txt.noData') }}</td>
          </template>
          </tbody>
        </table>
      </div>
    </div>

    <pagination v-if="tableData.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />

    <div class="modal-wrapper" v-if="saveData">
      <div class="modal-wrap">
        <article>
          <!--sub-title :text="`유지보수상세`" /-->
          <sub-title :text="$t('common.mainten')" />
          <div class="page-content">
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 15%">{{ $t('table.head.checkType') }}</div>
                <div class="item-content">
                  <!--select v-model="saveData.checkType">
                    <template v-for="item in checkTypes" :key="item.code">
                      <option :value="item.code">{{item.codeName}}</option>
                    </template>
                  </select-->
                  <select v-model="saveData.checkType">
                    <option value="site">{{ $t('common.mainten') }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="item-row-container" v-if="saveData.checkType==='game'">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 15%">{{ $t('table.head.checkCode') }}</div>
                <div class="item-content">
                  <select ref="insGame" v-model="saveData.checkCode">
                    <option value="">{{ $t('common.choosegame') }}</option>
                    <template v-for="item in gameList" :key="item.code">
                      <option :value="item.code">{{item.codeName}}</option>
                    </template>
                  </select>
                </div>
              </div>
            </div>
            <!--div class="item-row-container" >
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 15%">긴급 점검 여부</div>
                <div class="item-content">
                  <select ref="insGame" v-model="saveData.isEmergency">
                    <option value="N">아니요</option>
                    <option value="Y">예</option>
                  </select>
                </div>
              </div>
            </div>
            <div-- class="item-row-container" v-if="modalType === 'c'">
             <div class="item-wrap">
               <div class="item-title" style="flex: 0 0 15%">{{ $t('table.body.isYN') }}</div>
               <div class="item-content">
                 <select ref="insGame" v-model="saveData.isCheckOff">
                   <option value="N">{{ $t('common.Y') }}</option>
                   <option value="Y">{{ $t('common.N') }}</option>
                 </select>
               </div>
             </div>
           </div-->
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 15%">{{ $t('table.body.startend') }}</div>
                <div class="item-content">
                  <date-selector :class="'searchbar-content'" @setStartDate="setSaveDataStartDate" @setEndDate="setSaveDataEndDate" :startDefault="saveData.startDate" :endDefault="saveData.endDate" :configs="dateConfigs" :option="false"/>
                </div>
              </div>
            </div>
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 15%">{{ $t('table.head.content') }}</div>
                <div class="item-content">
                  <textarea ref="insMemo" v-model="saveData.contents"></textarea>
                </div>
              </div>
            </div>
            <div class="modal-btns">
              <a @click="regist" v-if="modalType === 'u'">{{ $t('button.modify') }}</a>
              <a @click="updateregist" v-if="modalType === 'c'">{{ $t('common.save') }}</a>
              <a @click="closeDetail">{{ $t('button.close') }}</a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import lodash from 'lodash'
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import TableHead from '@/components/main/table/Head.vue'
import UiCheck from '@/components/ui/UiCheckSet'
import subTitle from '@/components/main/PageSubTitle'
import { getCode } from '@/api/member.js'
import { settingCheckDetail, settingCheckInsert, settingCheckList, settingInspect, siteCheckOffByIdx } from '@/api/setting.js'
import { GAME_INFO_LIST, GAME_CODE_NAME } from '@/libs/constants'
import { numberWithCommas, getDateStr, replaceDateT } from '@/libs/utils.js'
import Pagination from '@/components/common/Pagination'
import BtnRegist from '@/components/ui/BtnRegist'
import { addMinutes } from 'date-fns'
import Memo from '@/components/common/memo'

export default {
  name: 'MaintainHistory',
  components: {
    SearchFilter,
    DateSelector,
    TableHead,
    UiCheck,
    subTitle,
    Pagination,
    BtnRegist,
    Memo
  },
  data: function () {
    return {
      tableData: [],
      headInfo: {
        fstColumn: false,
        dataList: [
          'siteCheckIdx',
          'checkType',
          'startday',
          'endday',
          // 'isEmergency',
          'status',
          'note'
        ]
      },
      reqData: {

      },
      pageInfo: {},
      betList: [],
      codeList: [],
      convertData: {},
      gameType: {},
      tabList: {},
      totalWlYn: false,
      currentPage: '',
      gameList: [],
      gameAllYn: false,
      days: [],
      modal: {
        active: false,
        target: '',
        list: []
      },
      startDefault: '',
      endDefault: '',
      dateConfigs: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      saveData: null,
      modalType: ''
    }
  },
  methods: {
    async forceStop (item) {
      const confirmMessage = '아직 점검시간입니다. 그래도 점검을 종료하시겠습니까?'
      if (confirm(confirmMessage)) {
        const req = {
          siteCheckIdx: item.siteCheckIdx
        }
        // req.endDate = getDateStr(addMinutes(new Date(), -1), 'yyyy-MM-dd HH:mm:ss')
        // req.isEmergency = 'N'
        console.log(req)

        const res = await siteCheckOffByIdx(req)
        console.log(res)
        if (res.resultCode === '0') {
          alert('점검 강제 종료 완료')
          await this.setTableData(1)
        } else {
          alert('점검 강제 종료, 다시 시도해주세요.')
        }
      }
    },
    async regist () {
      const req = lodash.cloneDeep(this.saveData)
      req.isEmergency = 'N'
      if (req.checkType === 'game' && !req.checkCode) {
        alert('수정할 점검할 게임을 선택해주세요.')
        this.$$refs.insGame.focus()
        return
      }
      if (req.checkType === 'site') {
        req.checkCode = 'site'
      }
      if (!req.contents) {
        // alert('수정할 점검내용을 입력해주세요.')
        // this.$$refs.insMemo.focus()
        // return
      }

      console.log(req.startDate)

      if (req.startDate && typeof req.startDate !== 'string') {
        req.startDate = getDateStr(req.startDate, 'yyyy-MM-dd HH:mm:ss')
      }
      if (req.endDate && typeof req.endDate !== 'string') {
        req.endDate = getDateStr(req.endDate, 'yyyy-MM-dd HH:mm:ss')
      }

      console.log(req)

      const res = await settingCheckInsert(req)
      console.log(res)
      if (res.resultCode === '0') {
        alert('점검 수정 완료')
        this.closeDetail()
        this.setTableData(this.pageInfo)
      } else {
        alert('점검 수정 실패, 다시 시도해주세요.')
      }
    },
    async updateregist () {
      const req = lodash.cloneDeep(this.saveData)
      req.isEmergency = 'N'
      if (req.checkType === 'site') {
        req.checkCode = 'site'
      }
      if (!req.contents) {
        // alert('점검내용을 입력해주세요.')
        // this.$$refs.insMemo.focus()
        // return
      }

      if (req.startDate && typeof req.startDate !== 'string') {
        req.startDate = getDateStr(req.startDate, 'yyyy-MM-dd HH:mm:ss')
      }
      if (req.endDate && typeof req.endDate !== 'string') {
        req.endDate = getDateStr(req.endDate, 'yyyy-MM-dd HH:mm:ss')
      }

      console.log(req)
      const res = await settingCheckInsert(req)
      console.log(res)
      if (res.resultCode === '0') {
        alert('점검 등록 완료')
        location.reload()
      } else {
        alert('점검 등록 실패, 다시 시도해주세요.')
      }
    },
    async setCheckType () {
      const checkTypes = await getCode('inspectType')
      console.log(checkTypes)
      const gameList = await getCode('kplay')
      const gList = []
      for (const loop of gameList) {
        const groupCode = loop.groupCode
        const gameCode = loop.code
        const gTxtCode = GAME_INFO_LIST[groupCode][gameCode.toString()]
        if (gTxtCode) {
          const gameTextKey = this.$t(`gameList.${gTxtCode}`)
          loop.codeName = gameTextKey
          gList.push(loop)
        }
      }

      this.checkTypes = checkTypes
      this.gameList = gList
    },
    async pageSeach () {
      await this.setTableData()
    },
    getDate (date) {
      return date
        .toISOString()
        .split('T')[0]
        .split('-')
        .map(v => Number(v))
    },
    async openDetail (type, siteCheckIdx) {
      this.modalType = type
      if (type === 'c') {
        this.saveData = {
          isEmergency: 'N',
          startDate: new Date(),
          endDate: new Date()
        }
      } else if (type === 'u') {
        const req = {
          siteCheckIdx
        }
        const res = await settingCheckDetail(req)
        console.log(res)
        if (!res.data) {
          return
        }
        const detail = res.data.detail
        console.log(detail)

        this.saveData = {
          ...detail,
          startDate: new Date(detail.startDate.replace(' ', 'T')),
          endDate: new Date(detail.endDate.replace(' ', 'T'))
        }
      }
    },
    closeDetail () {
      this.saveData = null
    },
    setStartDate (date) {
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(0, 0, 0)
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    setEndDate (date) {
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(23, 59, 59)
      } else {
        _date = date[0]
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    setSaveDataStartDate (date) {
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(0, 0, 0)
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.saveData.startDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    setSaveDataEndDate (date) {
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(23, 59, 59)
      } else {
        _date = date[0]
      }
      this.endDefault = _date
      this.saveData.endDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    async setTableData (page) {
      if (page) {
        this.reqData.page = page
      } else {
        this.reqData.page = 1
      }
      const req = lodash.cloneDeep(this.reqData)
      console.log(req)
      const res = await settingCheckList(req)
      console.log(res)
      if (!res.data) {
        return
      }
      if (res.data.pageInfo) {
        this.pageInfo = res.data.pageInfo
        this.pageInfo.tatal_list_count = numberWithCommas(this.pageInfo.tatal_list_count)
        this.pageInfo.tatal_page_count = numberWithCommas(Number(this.pageInfo.tatal_page_count) + 1)
      }
      this.tableData = res.data.list
      this.tableData.forEach(item => {
        item.startDate = replaceDateT(item.startDate)
        item.endDate = replaceDateT(item.endDate)
      })
    }
  },
  async created () {
    this.setCheckType()
    this.setStartDate()
    this.setEndDate()
    await this.setTableData(1)
  }
}
</script>

<style scoped>
.pt50 {padding-top: 50px;}
.modal-wrapper {display: flex;font-size: 16px;}
.item-content textarea {border-radius: 2px;border: 1px solid #aaa;background-color: #fafafa;resize: none;padding: 10px;width: calc(100% - 20px);height: 100px;}
.btn-status-change.approve {
    width: 150px;
    height: 35px;
    font-size: 16px;
    background: #353535;
    border: 0;
    font-weight: normal;
}
.main-sub-data-item {
  flex: 1 1 0;
  margin-right: 10px;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 3px 3px 12px 0px #d1d1d1;
  display: flex;
}
.main-sub-data-item > div {
  padding: 8px 0;
  text-align: center;
  box-sizing: border-box;
}
.sub-data-item-title {
  background: #ab2926;
  color: #fff;
  font-weight: 700;
  width: 35%;
}
.sub-data-item-content {
  font-weight: 700;
  text-align: right;
  width: 65%;
}
.main-sub-data-item:last-child {
  margin-right: 0;
}
.btn-total-data {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  background: #2f4858;
  width: fit-content;
  border-radius: 3px;
  border: 2px solid #2f4858;
}
.btn-total-data:hover {
  color: #2f4858;
  background: #f1f1e6;
}
.btn-total-data > i {
  margin-right: 5px;
}
.btn-total-data > span {
  font-weight: 700;
  letter-spacing: 2px;
}
.mainTable >>> th:nth-child(4){
  width:94px;
}

.week_day {
  position: absolute;
  left: 4px;
  top: 4px;
  font-weight: 900;
}
.week_no {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 4px;
}
.maintenance {
  font-weight: 800;
}
.btn-link:hover {
  font-weight: 800;
}
.modalTable {
  max-width: unset;
}
.modalTable > table {
  table-layout: unset;
}
.modalTable td {
  width: unset;
  height: unset;
}
.modal-close:hover {
  font-weight: normal;
}
.memoWrap {
  height: 100%;
  padding: 10px;
  overflow-y: scroll;
  white-space: pre-wrap;
  text-align:left;
  max-height:90px;
  line-height:18px;
}
</style>
